import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from '../components/seo';

const PortfolioComercial = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  const estilo1 = "box-maior box-left box-hover maior-bg lazy"
  const estilo2 = "box-menor box-right box-hover menor-bg lazy"

  return (
    <Layout>
      <SEO 
        title={frontmatter.title_seo}
        description={frontmatter.description_seo} 
      />
      <section className="portfolio-item top-espaco clearfix">
        <div className="info-container">
          <h5>{frontmatter.title}</h5>
          <p><span>{frontmatter.description}</span></p>
          <p>Projeto: <br /><span>{frontmatter.projeto}</span></p>
          <p>Obra: <br /><span>{frontmatter.obra}</span></p>
          <p>Localização: <br /><span>{frontmatter.localizacao}</span></p>
          <p>Data: <br /><span>{frontmatter.ano}</span></p>
          <p>Tipo: <br /><span>{frontmatter.tipo}</span></p>
          <p>Fotos: <br /><span>{frontmatter.fotos}</span></p>
        </div>
        <div className="container">
          <div className="row">
            {frontmatter.galeriaFotos.map((foto, i) => [
              <a
                key={i}
                href={foto.childImageSharp.sizes.src}
                data-fancybox="gallery"
              >
                <Img
                  className={i % 2 === 0 ? estilo1 : estilo2} 
                  fluid={foto.childImageSharp.fluid} 
                />
              </a>
            ])}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PortfolioComercial

export const pageQuery = graphql`
  query PortfolioComercialQuery($id: String!) {
    markdownRemark( 
      id: { eq: $id }
    ) {
      id
      html
      excerpt(pruneLength: 148)
      frontmatter {
        title_seo
        description_seo
        slug
        title
        description
        projeto
        obra
        localizacao
        ano
        tipo
        fotos
        galeriaFotos {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1980, maxHeight: 768, quality: 100) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`